<template>
  <div class="manage">
    <el-card class="step-area">
      <div class="step">
        <div class="step-content" :class="active >= '1' ? 'activeColor' : ''">
          <div class="step-line"></div>
          <div class="step-item">
            <div class="step-icon">
              <img
                v-if="active >= '1'"
                src="../../../assets/imgs/step/choose_1.png"
              />
              <img v-else src="../../../assets/imgs/step/1.png" />
            </div>
            <div class="step-word">发起合同</div>
          </div>
        </div>
        <div class="step-content" :class="active >= '2' ? 'activeColor' : ''">
          <div class="step-line"></div>
          <div class="step-item">
            <div class="step-icon">
              <img
                v-if="active >= '2'"
                src="../../../assets/imgs/step/choose_2.png"
              />
              <img v-else src="../../../assets/imgs/step/2.png" />
            </div>
            <div class="step-word">确认合同条款</div>
          </div>
        </div>
        <div class="step-content" :class="active >= '3' ? 'activeColor' : ''">
          <div class="step-line"></div>
          <div class="step-item">
            <div class="step-icon">
              <img
                v-if="active >= '3'"
                src="../../../assets/imgs/step/choose_3.png"
              />
              <img v-else src="../../../assets/imgs/step/3.png" />
            </div>
            <div class="step-word">双方签约</div>
          </div>
        </div>
        <div class="step-content" :class="active >= '4' ? 'activeColor' : ''">
          <div class="step-line"></div>
          <div class="step-item">
            <div class="step-icon">
              <img
                v-if="active >= '4'"
                src="../../../assets/imgs/step/choose_4.png"
              />
              <img v-else src="../../../assets/imgs/step/4.png" />
            </div>
            <div class="step-word">账单付款</div>
          </div>
        </div>
        <div class="step-content" :class="active >= '5' ? 'activeColor' : ''">
          <div class="step-line"></div>
          <div class="step-item">
            <div class="step-icon">
              <img
                v-if="active >= '5'"
                src="../../../assets/imgs/step/choose_5.png"
              />
              <img v-else src="../../../assets/imgs/step/5.png" />
            </div>
            <div class="step-word">过户提货</div>
          </div>
        </div>
        <div
          class="step-content"
          :class="active >= '6' ? 'activeColor' : ''"
          style="width: auto; flex-basis: auto"
        >
          <!-- <div class="step-line"></div> -->
          <div class="step-item">
            <div class="step-icon">
              <img
                v-if="active >= '6'"
                src="../../../assets/imgs/step/choose_6.png"
              />

              <img v-else src="../../../assets/imgs/step/6.png" />
            </div>
            <div class="step-word">完成</div>
          </div>
        </div>
      </div>
    </el-card>
    <el-card class="form" v-if="active === '1'">
      <div class="head-title">第一步：发起合同</div>
      <div class="form-content">
        <div class="block">
          <div class="label"><span style="color: red">*</span>合同日期</div>
          <el-date-picker
            v-model.trim="form.date"
            type="date"
            placeholder="选择日期"
            size="small"
            value-format="yyyy-MM-dd"
          >
          </el-date-picker>
        </div>

        <div class="block">
          <div class="label"><span style="color: red">*</span>订单类型</div>
          <el-radio-group v-model.trim="form.radio">
            <el-radio
              v-for="(item, index) in radioList"
              :key="index"
              :label="item.code"
              >{{ item.value }}</el-radio
            >
          </el-radio-group>
        </div>

        <div class="block">
          <div class="label"><span style="color: red">*</span>卖家公司</div>
          <div class="content">
            <el-input
              v-model.trim="sellerCompanyName"
              style="width: 350px"
              size="small"
              placeholder="输入卖家公司关键字,搜索并选择卖方及其交易员"
            ></el-input>
          </div>
        </div>
        <div
          style="
            margin-left: 120px;
            position: absolute;
            z-index: 99;
            border: 1px solid #dcdcdc;
            background: white;
            width: 350px;
            max-height: 180px;
            overflow-y: auto;
          "
          v-if="options.length > 0"
        >
          <ul style="list-style-type: none; cursor: pointer">
            <li v-for="(item, index) in options" :key="index">
              <div
                style="
                  padding: 10px 10px 0 10px;
                  box-sizing: border-box;
                  width: 100%;
                  white-space: nowrap;
                  text-overflow: ellipsis;
                  overflow: hidden;
                  color: #606266;
                "
                @click="handleSelectBuyerCompany(item)"
              >
                {{ item.companyName }}
              </div>
            </li>
          </ul>
        </div>
        <div style="margin-left: 120px">
          <ul style="list-style-type: none; cursor: pointer">
            <li v-for="(item, index) in sellerCompanyList" :key="index">
              【<span style="color: red; line-height: 22px">{{
                item.sellerCompanyName
              }}</span>
              <el-radio-group
                v-model.trim="companyRadio"
                @change="chooseCompany(item)"
                style="margin-left: 20px; margin-top: 10px"
              >
                <div style="display: flex; flex-wrap: wrap">
                  <div
                    style="width: 180px; height: 24px"
                    v-for="(item, index) in item.users"
                    :key="index"
                  >
                    <el-radio :label="item.userId">{{ item.name }}</el-radio>
                  </div>
                </div> </el-radio-group
              >】
            </li>
          </ul>
        </div>

        <div class="block">
          <div class="label"><span style="color: red">*</span>合同编号</div>
          <div class="content-block__content">
            <el-input
              style="width: 350px"
              placeholder="请输入合同编号"
              size="small"
              v-model.trim="contractNumber"
              @input="checkNum"
              maxlength="30"
              show-word-limit
            ></el-input>
          </div>
        </div>

        <div class="block">
          <div class="label"><span style="color: red">*</span>合同签署地址</div>
          <div class="content-block__content">
            <el-input
              style="width: 350px"
              placeholder="请输入合同签署地址"
              size="small"
              v-model.trim="signAddress"
              @input="checkNum"
              maxlength="30"
              show-word-limit
            ></el-input>
          </div>
        </div>

        <div class="block">
          <div class="label"><span style="color: red">*</span>合同签署方式</div>
          <el-radio-group v-model.trim="form.signType">
            <el-radio
              v-for="(item, index) in signTypeList"
              :key="index"
              :label="item.code"
              >{{ item.value }}</el-radio
            >
          </el-radio-group>
        </div>

        <div
          class="block"
          style="margin-top: 5px; justify-content: space-between"
        >
          <div class="c-title"><span style="color: red"></span>合同明细</div>
          <div class="add-item-btn" @click="addItem">
            {{ tableData.length == 0 ? "新增商品" : "添加商品" }}
          </div>
        </div>

        <div class="table">
          <el-table
            :data="tableData"
            :header-cell-style="{
              'text-align': 'center',
              'font-weight': 'normal',
              'font-size': '12px',
              color: '#333',
            }"
            :cell-style="{
              'text-align': 'center',
              'font-weight': 'normal',
              'font-size': '12px',
              color: '#333',
            }"
            style="width: 100%"
            size="mini"
          >
            <!-- <el-table-column prop="productName" label="品种" width="260">
            </el-table-column> -->
            <el-table-column prop="commodityName" label="品名">
            </el-table-column>
            <el-table-column prop="productorName" label="品牌">
            </el-table-column>
            <el-table-column prop="warehouseName" label="交货仓库">
            </el-table-column>
            <el-table-column prop="weight" label="重量"> </el-table-column>
            <el-table-column prop="weightUnit" label="重量单位">
            </el-table-column>
            <el-table-column prop="price" label="价格"> </el-table-column>
            <el-table-column prop="priceUnit" label="价格单位">
            </el-table-column>
            <el-table-column prop="amountOfMoney" label="金额(元)">
            </el-table-column>
            <el-table-column label="操作" width="120">
              <template slot-scope="scope">
                <el-button
                  @click="deleteItem(scope.$index, tableData)"
                  type="text"
                  size="small"
                  v-show="scope.row.commodityName !== '合计'"
                  >删除</el-button
                >
                <el-button
                  type="text"
                  size="small"
                  @click="updateItem(scope.row)"
                  v-show="scope.row.commodityName !== '合计'"
                  >编辑</el-button
                >
              </template>
            </el-table-column>
          </el-table>
        </div>

        <div class="block" style="margin-top: 5px">
          <div class="label"><span style="color: red">*</span>选择合同模板</div>
          <div class="content-block" v-if="form.signType !== '0'">
            <span v-show="isShowTip"
              >未找到商品对应合同模板，请联系客服人员核实并维护缺失的模板信息</span
            >
            <div v-for="(item, index) in templateNameList" :key="index">
              <el-radio-group v-model.trim="contractTemplateId">
                <el-radio type="radio" :label="item.contractTemplateId">
                  <span
                    class="radio-item"
                    @click="gotoContractTemp(item.contractTemplateId)"
                    >{{ item.contractTemplateName }}</span
                  >
                </el-radio>
              </el-radio-group>
            </div>
          </div>
          <div class="content-block-template" v-else>
            <el-radio-group>
              <el-radio type="radio">
                <span class="radio-item" @click="gotoContractTempOnline()"
                  >电子合同模板</span
                >
              </el-radio>
            </el-radio-group>
          </div>
        </div>
        <div style="display: flex; justify-content: center">
          <div class="send-btn" @click="sendToBuyer">下一步</div>
          <div class="send-btn" style="background: #2f3649" @click="cancle">
            取消
          </div>
        </div>
      </div>
    </el-card>

    <el-card v-if="active === '2'">
      <div class="head-title">第二步：确认合同条款</div>
      <div
        v-if="form.signType === '0'"
        style="border: 1px solid #f2f2f2; background: #f2f2f2; margin: 20px"
      >
        <div
          style="
            width: 218mm;
            margin: 0 auto;
            height: 40vh;
            overflow-y: scroll;
            overflow-x: hidden;
            background: #fff;
          "
        >
          <div>
            <contractTemplateCon
              ref="templateCon"
              type="sell"
              :showMark="showMark"
              :textarea="textarea"
              :contractNumber="contractNumber"
              :form="form"
              :signAddress="signAddress"
              :sellCompany="sellCompany"
              :buyerCompany="buyerCompany"
              :enterpriseInfo="enterpriseInfo"
              :salerInfo="salerInfo"
              :buyerInfo="buyerInfo"
              :contractDate="contractDate"
              :totalWeight="totalWeight"
              :totalAmount="totalAmount"
              :goodsData="goodsData"
              :price="price"
              :contenteditable="contenteditable"
              :optionsHtml="optionsHtml"
            ></contractTemplateCon>
          </div>
        </div>
      </div>
      <div v-else style="display: flex; justify-content: center">
        <div class="container" id="print-area-Info">
          <div class="info-title">购销合同</div>
          <div class="content">
            <div class="row-fst">
              <div class="left">
                <div>
                  <span>卖方:</span><span>{{ salerInfo.name }}</span>
                </div>
                <div>
                  <span>电话:</span><span>{{ salerInfo.contactNumber }}</span>
                </div>
                <div>
                  <span>传真:</span><span>{{ salerInfo.fax }}</span>
                </div>
                <div>
                  <span>地址:</span><span>{{ salerInfo.address }}</span>
                </div>
              </div>
              <div class="right">
                <div>
                  <span>买方:</span><span>{{ buyerInfo.name }}</span>
                </div>
                <div>
                  <span>电话:</span><span>{{ buyerInfo.contactNumber }}</span>
                </div>
                <div>
                  <span>传真:</span><span>{{ buyerInfo.fax }}</span>
                </div>
                <div>
                  <span>地址:</span><span>{{ buyerInfo.address }}</span>
                </div>
              </div>
            </div>
            <div class="row-sec">
              <div class="row">
                <div class="cell">合同编号</div>
                <div class="cell">签约时间</div>
                <div class="cell">签约方式</div>
                <div class="cell">总重量</div>
                <div class="cell">总金额</div>
              </div>
              <div class="row">
                <div class="cell">{{ contractNumber }}</div>
                <div class="cell">{{ contractDate }}</div>
                <div class="cell">/</div>
                <div class="cell">{{ totalWeight }}</div>
                <div class="cell">{{ totalAmount }}</div>
              </div>
            </div>
            <div
              style="
                border-top: 1px solid #333;
                border-right: 1px solid #333;
                line-height: 30px;
              "
            >
              一、产品名称、规格、数量、单价、金额、供货时间及地点等。
            </div>
            <div class="row-sec">
              <div class="row">
                <div class="cell">产品名称</div>
                <div class="cell" style="width: 50px">单位</div>
                <div class="cell">品牌</div>
                <div class="cell" style="width: 50px">数量</div>
                <div class="cell" style="width: 80px">含税单价(元)</div>
                <div class="cell" style="width: 80px">金额(元)</div>
                <div class="cell">仓库</div>
              </div>
              <div class="row" v-for="(item, index) in goodsData" :key="index">
                <div class="cell">{{ item.commodityName }}</div>
                <div class="cell">{{ item.weightUnit }}</div>
                <div class="cell">{{ item.productorName }}</div>
                <div class="cell">{{ item.weight }}</div>
                <div class="cell">{{ item.price }}</div>
                <div class="cell">{{ item.amountOfMoney }}</div>
                <div class="cell" style="width: 200px">
                  {{ item.warehouseName }}
                </div>
              </div>
            </div>
            <div
              style="
                border-top: 1px solid #333;
                border-right: 1px solid #333;
                line-height: 30px;
              "
            >
              金额（人民币）：{{ price }}
            </div>
            <div class="row-last">
              <div
                class="row-last__title"
                :class="{ 'update-item': contenteditable }"
              >
                {{ contenteditable ? "修改合同条款：" : "合同条款：" }}
              </div>
              <div class="row-last__content" :contenteditable="contenteditable">
                <div v-html="optionsHtml" ref="items"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="btn-group" style="display: flex; justify-content: center">
        <div class="send-btn" @click="send">
          {{ showSave ? "保存并发送给卖方" : "发送给卖方" }}
        </div>
        <div class="send-btn" v-if="!showSave" @click="forceText">补充条款</div>
        <div class="send-btn" v-if="showSave" @click="saveText">取消修改</div>
        <div class="send-btn" @click="print">打印</div>
        <div class="send-btn" @click="goFirst">上一步</div>
        <div class="send-btn" style="background: #2f3649" @click="goFirst">
          取消
        </div>
      </div>
    </el-card>

    <el-card
      v-if="
        active === '3' || active === '4' || active === '5' || active === '6'
      "
    >
      <div class="contract-detail">
        <div class="head-title">合同详情</div>

        <div style="padding: 34px 45px 0; position: relative">
          <!-- <h2 class="no-title">合同编号：{{ contract.contractNumber }}</h2> -->
          <div class="detail-form">
            <el-row>
              <el-col :span="10">
                <div>
                  <span class="label">合同编号 </span>
                  <span>{{ contract.contractNumber }}</span>
                </div>
                <div>
                  <span class="label">买方公司 </span>
                  <span>{{ contract.buyerName }}</span>
                </div>
                <div>
                  <span class="label">买方交易员 </span>
                  <span>{{ contract.buyerTraderName }}</span>
                </div>
                <div>
                  <span class="label">订单类型 </span>
                  <span>{{ getOrderTypes(contract.orderType) }}</span>
                </div>
                <div>
                  <span class="label">支付类型 </span>
                  <span>{{ contract.payType }}</span>
                </div>
                <div>
                  <span class="label">合同重量 </span>
                  <span style="color: #ff6600">{{
                    contract.contractWeight
                  }}</span
                  >(吨)
                </div>
                <div>
                  <span class="label">实际重量 </span>
                  <span style="color: #ff6600">{{ contract.actualWeight }}</span
                  >(吨)
                </div>
                <div>
                  <span class="label">合同日期 </span>
                  <span>{{ contract.contractDate }}</span>
                </div>
                <!-- <div v-show="pageTag !== 'offlineSelling' && contractStatusId !== '91'">
                  <span class="label">提单：</span>
                  <span>{{contract.offlineLadingBill}}</span>
                </div> -->
                <div
                  v-show="
                    contract.contractType === '1' ||
                    contract.contractType === '3'
                  "
                >
                  <span class="label">水单 </span>
                  <span>
                    <img
                      src="../../../assets/imgs/hd40.png"
                      style="vertical-align: bottom"
                      @click="showFile('credentials')"
                    />
                  </span>
                </div>
                <div
                  v-show="
                    contract.contractType === '1' ||
                    contract.contractType === '3'
                  "
                >
                  <span class="label">合同附件 </span>
                  <span>
                    <img
                      src="../../../assets/imgs/hd40.png"
                      style="vertical-align: bottom"
                      @click="showFile('file')"
                    />
                  </span>
                </div>
                <div>
                  <span class="label">备注 </span>
                  <span>{{ contract.remark }}</span>
                  <span
                    style="color: blue; cursor: pointer"
                    @click="addRemark"
                    >{{ isEdit ? "修改备注" : "添加备注" }}</span
                  >
                </div>
              </el-col>
              <el-col :span="10">
                <div style="height: 30px">
                  <span> </span>
                </div>
                <div>
                  <span class="label">合同状态 </span>
                  <span style="color: red">{{
                    getContractStatuses(contract.contractStatus)
                  }}</span>
                </div>
                <div>
                  <span class="label">卖方交易员 </span>
                  <span>{{ contract.sellerTraderName }}</span>
                </div>
                <div>
                  <span class="label">合同类型 </span>
                  <span>{{ getContractTypes(contract.contractType) }}</span>
                </div>
                <div>
                  <span class="label">合同金额 </span>
                  <span style="color: #ff6600">{{
                    contract.contractAmount
                  }}</span
                  >(元)
                </div>
                <div>
                  <span class="label">已收金额 </span>
                  <span style="color: #ff6600">{{
                    contract.amountReceived
                  }}</span
                  >(元)
                </div>
                <div>
                  <span class="label">实际金额 </span>
                  <span style="color: #ff6600">{{ contract.actualAmount }}</span
                  >(元)
                </div>
                <div>
                  <span class="label">交货日期 </span>
                  <span>{{ contract.deliveryDate }}</span>
                </div>
                <div
                  v-show="
                    contract.contractType === '1' ||
                    contract.contractType === '3'
                  "
                >
                  <span class="label">提单 </span>
                  <span>
                    <img
                      src="../../../assets/imgs/hd40.png"
                      style="vertical-align: bottom"
                      @click="showFile('bill')"
                    />
                  </span>
                </div>
                <!-- <div v-show="pageTag === 'offlineSelling' || contractStatusId === '91'">
                  <span class="label">应收账款转让确认书：</span>
                  <span style="color: red;">未上传</span>
                </div>
                <div v-show="pageTag !== 'offlineSelling' && contractStatusId !== '91'">
                  <span class="label">支付凭证：</span>
                  <span>{{contract.offlineWaterBillOrPaymentVoucher}}</span>
                </div> -->
              </el-col>
            </el-row>
          </div>
          <h2 class="c-title">
            合同明细（<span>{{
              getWeightTonTotel(transferOrderStatusDesc)
            }}</span
            >）
          </h2>
          <el-table
            :data="detailTableData"
            :header-cell-style="{
              'text-align': 'center',
              'font-weight': 'normal',
              'font-size': '12px',
              color: '#333',
            }"
            :cell-style="{
              'text-align': 'center',
              'font-weight': 'normal',
              'font-size': '12px',
              color: '#333',
            }"
            style="width: 100%"
            size="mini"
          >
            <el-table-column prop="commodityName" label="品名">
            </el-table-column>
            <el-table-column prop="productorName" label="品牌">
            </el-table-column>
            <el-table-column prop="weight" label="合同重量"> </el-table-column>
            <el-table-column prop="weightUnit" label="重量单位">
            </el-table-column>
            <el-table-column prop="price" label="合同单价（元）">
            </el-table-column>
            <el-table-column prop="amount" label="合同金额(元)">
            </el-table-column>
            <el-table-column prop="deliveryWarehouse" label="交货仓库">
            </el-table-column>
          </el-table>

          <el-dialog
            title="添加备注"
            :visible.sync="dialogFormVisible"
            width="20%"
          >
            <span slot="title">{{ isEdit ? "修改备注" : "添加备注" }}</span>
            <el-form :model="remarkForm" style="width: 280px">
              <el-form-item label="备注" :label-width="formLabelWidth">
                <el-input
                  type="textarea"
                  v-model.trim="remarkForm.remark"
                ></el-input>
              </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
              <el-button size="mini" @click="dialogFormVisible = false"
                >取 消</el-button
              >
              <el-button size="mini" type="primary" @click="submitRemark"
                >确 定</el-button
              >
            </div>
          </el-dialog>

          <div class="btn-group">
            <div
              class="btn sec"
              v-if="contractStatusId === '99'"
              @click="showContractUpload('upload')"
            >
              上传合同
            </div>
            <div
              class="btn sec"
              v-if="
                contractStatusId === '100' &&
                userInfo.organizationId[0] === buyerId
              "
              @click="offlinePay"
            >
              线下支付
            </div>
            <div
              class="btn sec"
              v-if="
                contractStatusId === '201' &&
                userInfo.organizationId[0] === buyerId
              "
              @click="confirmGoods"
            >
              确认收货
            </div>
            <div
              class="btn sec"
              v-if="
                signType !== '0' &&
                (contractStatusId === '100' || contractStatusId === '201')
              "
              @click="showContractUpload('againUpload')"
            >
              重传合同
            </div>
            <div
              class="btn sec"
              v-if="contractStatusId === '90'"
              @click="confirm"
            >
              {{
                userInfo.organizationId[0] === sellerId
                  ? "确认合同"
                  : userInfo.organizationId[0] === buyerId
                  ? "取消合同"
                  : "取消合同"
              }}
            </div>
            <div
              class="btn sec"
              v-if="contractStatusId === '91'"
              @click="confirm"
            >
              {{
                userInfo.organizationId[0] === buyerId
                  ? "确认合同"
                  : userInfo.organizationId[0] === sellerId
                  ? "取消合同"
                  : "取消合同"
              }}
            </div>
            <div
              class="btn sec"
              v-if="contractStatusId == '70' || contractStatusId == '96'"
              @click="confirm"
            >
              取消合同
            </div>
            <div
              class="btn sec"
              v-if="signType === '0'"
              @click="downloadFile(fileId, '下载')"
            >
              下载过户单
            </div>
            <div
              class="btn sec"
              v-if="signType === '0'"
              @click="downloadFile(fileId, '打印')"
            >
              打印过户单
            </div>
            <div
              class="btn sec"
              @click="lookFileNew(fileIdNew)"
              v-if="
                signType === '0' ||
                (signType !== '0' && contractStatusId == '400')
              "
            >
              在线预览合同
            </div>
            <div
              class="btn sec"
              v-if="signType === '0'"
              @click="downloadFileNew(fileIdNew)"
            >
              下载电子合同
            </div>
            <div class="btn" @click="goback">返回</div>
          </div>
        </div>
      </div>
    </el-card>

    <el-dialog
      title="添加商品"
      :visible.sync="dialogFormVisible"
      :close-on-click-modal="false"
      width="35%"
    >
      <div slot="title">{{ isEdit ? "编辑商品" : "添加商品" }}</div>
      <div @click="hideContainer">
        <el-form
          :model="itemForm"
          ref="itemForm"
          :rules="rules"
          style="width: 450px; margin: 0 auto"
          size="small"
        >
          <el-form-item
            label="品名："
            :label-width="formLabelWidth"
            prop="productName"
          >
            <el-input
              v-model.trim="itemForm.productName"
              @focus="showCommodity"
              @input="searchCommodity"
              id="commodity"
              style="width: 260px"
              :disabled="tableData.length >= 2"
            ></el-input>

            <div class="container-alert" v-if="showCommodityContainer">
              <div class="head">请选择品名或输入品名的拼音</div>
              <div class="content">
                <div
                  v-for="(item, index) in comContent"
                  :key="index"
                  class="content-item"
                  :title="item.productName"
                  @click="handleSelectCategoryName(item)"
                >
                  {{ item.productName }}
                </div>
              </div>
              <!-- <div class="footer" id="comFooter">
                <div class="common" @mouseover="showAllCommodity">常用</div>
                <div class="key-group">
                  <div v-for="(item, index) in commodityContent" :key="index" class="key" @mouseover="hoverCommodity(item.value)">
                    {{ item.key }}
                  </div>
                </div>
              </div> -->
            </div>

            <div class="list" v-if="showCommoditylist">
              <ul style="list-style-type: none; cursor: pointer">
                <div
                  style="
                    border-bottom: 1px dashed #dcdcdc;
                    color: #656565;
                    font-size: 12px;
                    padding-left: 10px;
                  "
                >
                  输入中文，进行排序
                </div>
                <li
                  v-for="(item, index) in commoditySearchResult"
                  :key="index"
                  @click="handleSelectCategoryName(item)"
                >
                  {{ item.productName }}
                </li>
              </ul>
            </div>
          </el-form-item>
          <el-form-item
            label="品牌："
            :label-width="formLabelWidth"
            prop="brandName"
          >
            <el-input
              v-model.trim="itemForm.brandName"
              @focus="showProductor"
              @input="searchProductor"
              id="productor"
              style="width: 260px"
            ></el-input>

            <div class="container-alert" v-if="showProductorContainer">
              <div class="head">请选择品牌或输入品牌的拼音</div>
              <div class="content">
                <div
                  v-for="(item, index) in proContent"
                  :key="index"
                  class="content-item"
                  :title="item.brandName"
                  @click="handleSelectProducer(item)"
                >
                  {{ item.brandName }}
                </div>
              </div>
              <!-- <div class="footer" id="proFooter">
                <div class="common" @mouseover="showAllProductor">常用</div>
                <div class="key-group">
                  <div v-for="(item, index) in productorContent" :key="index" class="key" @mouseover="hoverProductor(item.value)">
                    {{ item.key }}
                  </div>
                </div>
              </div> -->
            </div>

            <div class="list" v-if="showProductorlist">
              <ul style="list-style-type: none; cursor: pointer">
                <div
                  style="
                    border-bottom: 1px dashed #dcdcdc;
                    color: #656565;
                    font-size: 12px;
                    padding-left: 10px;
                  "
                >
                  输入中文，进行排序
                </div>
                <li
                  v-for="(item, index) in productorSearchResult"
                  :key="index"
                  @click="handleSelectProducer(item)"
                >
                  {{ item.brandName }}
                </li>
              </ul>
            </div>
          </el-form-item>
          <el-form-item
            label="交货仓库："
            :label-width="formLabelWidth"
            prop="warehouseName"
          >
            <el-input
              v-model.trim="itemForm.warehouseName"
              @focus="showWarehouse"
              @input="searchWarehouse"
              id="warehouse"
              style="width: 260px"
            ></el-input>

            <div class="container-alert" v-if="showWarehouseContainer">
              <div class="head">请选择交货仓库或输入交货仓库的拼音</div>
              <div class="content">
                <div
                  v-for="(item, index) in wareContent"
                  :key="index"
                  class="content-item"
                  :title="item.warehouseName"
                  @click="handleSelectWarehouse(item)"
                >
                  {{ item.warehouseName }}
                </div>
              </div>
              <!-- <div class="footer" id="wareFooter">
                <div class="common" @mouseover="showAllWarehouse">常用</div>
                <div class="key-group">
                  <div v-for="(item, index) in warehouseContent" :key="index" class="key" @mouseover="hoverWarehouse(item.value)">
                    {{ item.key }}
                  </div>
                </div>
              </div> -->
            </div>

            <div class="list" v-if="showWarehouselist">
              <ul style="list-style-type: none; cursor: pointer">
                <div
                  style="
                    border-bottom: 1px dashed #dcdcdc;
                    color: #656565;
                    font-size: 12px;
                    padding-left: 10px;
                  "
                >
                  输入中文，进行排序
                </div>
                <li
                  v-for="(item, index) in warehouseSearchResult"
                  :key="index"
                  @click="handleSelectWarehouse(item)"
                >
                  {{ item.warehouseName }}
                </li>
              </ul>
            </div>
          </el-form-item>
          <el-form-item
            label="重量："
            :label-width="formLabelWidth"
            prop="weight"
          >
            <el-input
              v-model.trim="itemForm.weight"
              @input="
                itemForm.weight = itemForm.weight.replace(
                  /[^\d+(\.{0,1}\d+){0,1}]/g,
                  ''
                )
              "
              autocomplete="off"
              style="width: 260px"
            ></el-input>
            <span>{{ unit }}</span>
          </el-form-item>
          <el-form-item
            label="单价："
            :label-width="formLabelWidth"
            prop="price"
          >
            <el-input
              v-model.trim="itemForm.price"
              @input="
                itemForm.price = itemForm.price.replace(
                  /[^\d+(\.{0,1}\d+){0,1}]/g,
                  ''
                )
              "
              autocomplete="off"
              style="width: 260px"
            ></el-input>
            <span>元/{{ unit }}</span>
          </el-form-item>
          <el-form-item label="金额（元）：" :label-width="formLabelWidth">
            <el-input
              v-model.trim="amountOfMoney"
              disabled
              style="width: 260px"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <div class="add-dialog-footer">
              <div
                class="btn go-on"
                @click="goonAdd('itemForm')"
                v-if="!isEdit"
              >
                继续添加
              </div>
              <div class="btn confirm" @click="submitItems('itemForm')">
                完 成
              </div>
              <div class="btn cancel" @click="dialogFormVisible = false">
                取 消
              </div>
            </div>
          </el-form-item>
        </el-form>
      </div>
    </el-dialog>

    <el-dialog
      title="双章合同"
      :visible.sync="contractVisible"
      width="30%"
      class="contractDialog"
    >
      <el-table :data="contractTableData" style="width: 100%">
        <el-table-column prop="name" label="名称" width="120">
        </el-table-column>
        <el-table-column label="操作" width="100">
          <template slot-scope="scope">
            <div style="display: flex; align-items: baseline">
              <el-upload
                accept=".pdf, .jpg, .png, .jpeg"
                class="upload-demo"
                v-show="scope.row.showUpload"
                :multiple="false"
                :limit="limit"
                :ref="'upload' + scope.row.tag"
                :data="{ tag: scope.row.tag }"
                action
                :http-request="uploadContract"
              >
                <el-button type="text">上传</el-button>
              </el-upload>
              <el-button
                type="text"
                @click="check(scope.row.tag)"
                :class="{ 'see-btn': scope.row.showUpload }"
                v-show="scope.row.file"
                >查看</el-button
              >
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div slot="footer" class="dialog-footer">
        <el-button @click="contractVisible = false" size="mini"
          >取 消</el-button
        >
        <el-button type="primary" @click="confirmContract" size="mini"
          >确 定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import store from "@/store";
import { getNowFormatDate } from "../../../utils/formatTime";
import contractTemplateCon from "../../components/contractTemplate";
import {
  getContractStatuses,
  getSignType,
  getContractTypes,
  getOrderTypes,
} from "../../../utils/formatTime";
import * as BigNumber from "bignumber.js";
import convertCurrency from "../../../utils/formatPrice";

import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import axios from "axios";
let contentWidth = "210" * 3.66; // A4纸大小
let contentHeight = "297" * 4.4;
const http = axios.create({
  baseURL: "/",
  headers: {
    "Content-Type": "application/json",
    responseType: "blob",
  },
});

export default {
  name: "directSelling",
  components: {
    contractTemplateCon,
  },
  data() {
    return {
      form: {
        date: getNowFormatDate(new Date()),
        radio: "0",
        signType: "0",
      },
      itemForm: {
        id: "",
        productName: "",
        productName: "",
        brandName: "",
        warehouseName: "",
        weight: "",
        price: "",
      },
      formLabelWidth: "100px",
      rules: {
        productName: [
          { required: true, message: "请输入品种", trigger: "change" },
        ],
        productName: [
          { required: true, message: "请输入品名", trigger: "change" },
        ],
        brandName: [
          { required: true, message: "请输入品牌", trigger: "change" },
        ],
        warehouseName: [
          { required: true, message: "请输入交货仓库", trigger: "change" },
        ],
        weight: [{ required: true, message: "请输入重量", trigger: "change" }],
        price: [{ required: true, message: "请输入单价", trigger: "change" }],
      },

      tableData: [
        {
          commodityName: "合计",
          amountOfMoney: 0.0,
          weight: 0.0,
        },
      ],

      active: "1",

      dialogFormVisible: false,

      sellerCompanyId: "",
      sellerCompanyName: "",
      sellerCompanyList: [],
      sellerOperatorId: "",
      sellerOperatorName: "",

      contractNumber: "",
      signAddress: "",

      searchBtnId: "",
      sendBtnId: "",
      radioList: [],
      signTypeList: [],
      url: {},

      currentCategoryId: "",
      currentCommodityId: "",
      currentProductorId: "1",
      currentWarehouseId: "",

      templateNameList: [],
      contractTemplateId: "",
      contractTemplateName: "",

      unit: "",

      companyRadio: "",
      options: [],
      optionsHtml: "",

      isEdit: false,

      isShowTip: false,

      hasError: false,

      showCategoryContainer: false,
      showCommodityContainer: false,
      showProductorContainer: false,
      showWarehouseContainer: false,

      showCategorylist: false,
      showCommoditylist: false,
      showProductorlist: false,
      showWarehouselist: false,

      categoryContent: [],
      cateContent: [],
      allCategory: [],
      categorySearchResult: [],

      commodityContent: [],
      comContent: [],
      allCommodity: [],
      commoditySearchResult: [],

      productorContent: [],
      proContent: [],
      allProductor: [],
      productorSearchResult: [],

      warehouseContent: [],
      wareContent: [],
      allWarehouse: [],
      warehouseSearchResult: [],

      // 第二步
      buyerInfo: {},
      salerInfo: {},
      contenteditable: false,
      showSave: false,
      showMark: true,
      goodsData: [],
      contractDate: "",
      // contractNumber: '',
      totalAmount: "",
      totalWeight: "",
      loading: "",

      textarea: "",

      unifiedSocialCreditCode: "",
      registerAddress: "",
      legalPerson: "",

      enterpriseInfo: {},

      // 第三步
      contract: {},
      fileId: "",
      fileIdNew: "",
      contractStatusId: "",
      contractId: "",
      buyerId: "",
      sellerId: "",
      constractDetail: "",
      signType: "",
      transferOrderStatusDesc: "",
      detailTableData: [],
      contractVisible: false,
      contractTableData: [
        { name: "买方合同文件", file: "", tag: "buyer", showUpload: false },
        { name: "卖方合同文件", file: "", tag: "seller", showUpload: false },
        { name: "双章合同文件", file: "", tag: "both", showUpload: true },
      ],
      limit: 1,
      remarkForm: {
        remark: "",
      },
      selectItem: {},
    };
  },
  computed: {
    amountOfMoney() {
      return (+(this.itemForm.weight * this.itemForm.price)).toFixed(2);
    },
    price() {
      return convertCurrency(this.totalAmount);
    },
    userInfo() {
      return this.$store.state.UserInfo;
    },
    buyerCompany() {
      return this.enterpriseInfo;
    },
    sellCompany() {
      let company = {};
      company.fullNameOfEnterprise = this.sellerCompanyName.split("【")[0];
      company.unifiedSocialCreditCode = this.unifiedSocialCreditCode;
      company.registerAddress = this.registerAddress;
      company.legalPerson = this.legalPerson;
      return company;
    },
  },
  created() {
    this.getDirectSellingInfo();
    //获取订单类型
    this.getTypes();
    this.getEnterpriseInfo();
    if (this.$route.query.active.match(/3|4|5|6/g)) {
      this.active = this.$route.query.active;
      this.getDetail();
    } else {
      this.active = "1";
    }
  },
  watch: {
    sellerCompanyName: function (val) {
      if (!val) {
        this.sellerCompanyList = [];
        return;
      }
      if (val.indexOf("【") > -1) {
        this.options = [];
        return;
      }
      this.querySearchSellerCompany();
    },
  },
  methods: {
    getContractStatuses,
    getContractTypes,
    getOrderTypes,
    getSignType,
    getEnterpriseInfo() {
      let params = {
        process_id: "00000201", // 流程ID
        // 传参  查询则是查询条件  可传可不传等
        nodeInfo: {},
      };
      this.$post("a6c611cc-ded1-4a49-8823-0c66f6ebf7d9", params)
        .then((res) => {
          this.enterpriseInfo = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getTypes() {
      let params = {};
      this.$post("355cc1ed-6673-a8fe-737a-7b915fca6ff8", params)
        .then((res) => {
          const { data } = res;
          this.radioList = data.orderTypes;
          this.signTypeList = data.signType;
        })
        .catch((err) => {});
    },
    getDirectSellingInfo() {
      let params = {
        process_id: "00000213", //流程ID
        nodeInfo: {}, //传参  查询则是查询条件  可传可不传等
      };
      this.$post("a6c611cc-ded1-4a49-8823-0c66f6ebf7d9", params)
        .then((result) => {
          const { data } = result;
          this.searchBtnId = data.button.NodeId_1;
          this.sendBtnId = data.button.NodeId_2;
          this.url = data.url;
        })
        .catch((err) => {});
    },
    searchCompanies() {
      if (this.sellerCompanyName == "") {
        this.$alert("卖方公司名不能为空！");
        return;
      }
      let params = {
        process_id: "00000213",
        execution_type: "get", //get 代表读请求 获取数据,  set	代表写请求 编辑等操作
        nextProcessNode: this.searchBtnId, // 上面接口的  button.NodeId_1字段的值
        nodeInfo: {
          companyName: this.sellerCompanyName.split("【")[0], //公司名称
          size: -1, //分页页容量（从0开始，-1代表查全部）
          page: 0, //分页页码（从0开始）
        },
      };
      this.$post("a6c611cc-ded1-4a49-8823-0c66f6ebf7d9", params)
        .then((result) => {
          const { data } = result;
          this.sellerCompanyList = [];
          data.content.forEach((item) => {
            if (this.sellerCompanyName.split("【")[0] == item.companyName) {
              this.sellerCompanyList.push({
                sellerCompanyId: item.companyId,
                sellerCompanyName: item.companyName,
                users: item.users,
              });
            }
          });
          let users = this.sellerCompanyList[0]["users"];
          this.sellerCompanyName = `${
            this.sellerCompanyList[0]["sellerCompanyName"]
          }【${users[users.length - 1].name}】`;
          this.sellerCompanyId = `${this.sellerCompanyList[0]["sellerCompanyId"]}`;
          this.companyRadio = users[users.length - 1].userId;

          this.sellerOperatorId = users[users.length - 1].userId;
          this.sellerOperatorName = users[users.length - 1].name;
        })
        .catch((err) => {});
    },
    chooseCompany(data) {
      let user = "";
      this.sellerCompanyId = data.sellerCompanyId;
      data.users.forEach((item) => {
        if (item.userId == this.companyRadio) {
          user = item.name;
        }
      });
      this.sellerCompanyName = `${data.sellerCompanyName}【${user}】`;
      this.sellerOperatorName = user;
      this.sellerOperatorId = this.companyRadio;
    },
    addItem() {
      this.isEdit = false;
      this.dialogFormVisible = true;
      this.currentCategoryId = "";
      this.currentCommodityId = "";
      this.currentProductorId = "";
      this.currentWarehouseId = "";
      this.$nextTick(() => {
        this.$refs.itemForm.resetFields();
        this.showCategoryContainer = false;
        this.showCommodityContainer = false;
        this.showProductorContainer = false;
        this.showWarehouseContainer = false;
        this.showCategorylist = false;
        this.showCommoditylist = false;
        this.showProductorlist = false;
        this.showWarehouselist = false;
      });
      Object.keys(this.itemForm).forEach((item) => {
        this.itemForm[item] = "";
      });
      // //品类搜索
      // this.querySearchSCategory();
      // this.querySearchSCategory("classifiedByPinyin");
      // 品名搜索
      this.querySearchSCategoryName();
      // this.querySearchSCategoryName('classifiedByPinyin')
      //仓库搜索
      this.querySearchSWarehouse();
      // this.querySearchSWarehouse("classifiedByPinyin");
      if (this.tableData.length >= 2) {
        this.$set(
          this.itemForm,
          "productName",
          this.tableData[0].commodityName
        );
        this.currentCommodityId = this.selectItem.productId;
        this.itemForm.productName = this.selectItem.productName;
        setTimeout(() => {
          this.itemForm.productName = this.selectItem.productName;
        }, 0);
        this.unit = this.selectItem.sweightunit;
        this.itemForm.brandName = "";
      }
    },
    sendToBuyer() {
      this.templateNameList.forEach((item) => {
        if (item.contractTemplateId == this.contractTemplateId) {
          this.contractTemplateName = item.contractTemplateName;
        }
      });
      if (!this.form.date) {
        this.$alert("合同日期不能为空");
        return;
      } else if (!this.sellerCompanyName) {
        this.$alert("卖方公司名不能为空");
        return;
      } else if (!this.contractNumber) {
        this.$alert("合同编号不能为空");
        return;
      } else if (!this.signAddress) {
        this.$alert("合同签署地址不能为空");
        return;
      } else if (this.tableData.length == 0) {
        this.$alert("请添加商品");
        return;
      } else if (this.form.signType !== "0" && !this.contractTemplateId) {
        this.$alert("请选择合同模板");
        return;
      } else if (!this.sellerOperatorId) {
        this.$alert("请选择卖方操作员");
        return;
      }

      let contractDetails = [];
      this.tableData.forEach((item) => {
        if (item.id) {
          contractDetails.push(item);
        }
      });
      let totalWeight = 0;
      contractDetails.forEach((item) => {
        if (item.weightUnit == "千克") {
          totalWeight += +item.weight / 1000;
        } else {
          totalWeight += +item.weight;
        }
      });

      let buyerContractInfo = {
        totalWeight: totalWeight,
        totalAmount: this.totalAmount,
        goodsData: contractDetails,
        contractDate: this.form.date,
        orderType: this.form.radio,
        signType: this.form.signType,
        sellerCompanyId: this.sellerCompanyId, //卖家公司id（定向采购时传该参数）
        sellerCompanyName: this.sellerCompanyNameTemp, //卖家公司名称（定向采购时传该参数）
        sellerOperatorId: this.sellerOperatorId,
        sellerOperatorName: this.sellerOperatorName,
        contractNumber: this.contractNumber,
        signAddress: this.signAddress,
        contractTemplateId: this.contractTemplateId, //合同模板id（定向销售和定向采购时传该参数）
        contractTemplateName: this.contractTemplateName, //合同模板名称（定向销售和定向采购时传该参数）
      };
      localStorage.setItem(
        "buyerContractInfo",
        JSON.stringify(buyerContractInfo)
      );
      // this.$router.push({
      //   path: "/contract",
      //   query: { tag: "buyer", id: this.contractTemplateId }
      // });
      this.tag = "buyer";
      this.active = "2";
      this.renderDom();
      if (this.form.signType !== "0") {
        this.getContractItems();
      }
    },
    submitItems(formName, tag) {
      this.hasError = false;
      this.$refs[formName].validate((valid) => {
        if (valid) {
          // if (!this.currentCategoryId) {
          //   this.$alert("请重新选择正确的品种");
          //   this.hasError = true;
          //   this.dialogFormVisible = true;
          //   return;
          // } else
          if (!this.currentCommodityId) {
            this.$alert("请重新选择正确的品名");
            this.hasError = true;
            this.dialogFormVisible = true;
            return;
          } else if (!this.currentWarehouseId) {
            this.$alert("请重新选择正确的交货仓库");
            this.hasError = true;
            this.dialogFormVisible = true;
            return;
          }
          if (!tag) {
            this.dialogFormVisible = false;
          }
          const { productName, brandName, warehouseName, weight, price } =
            this.itemForm;
          if (!this.itemForm.id) {
            this.tableData.unshift({
              id: Date.now(),
              // categoryId: this.currentCategoryId,
              // categoryName: categoryName,
              commodityId: this.currentCommodityId,
              commodityName: productName,
              productorId: this.currentProductorId,
              productorName: brandName,
              warehouseId: this.currentWarehouseId,
              warehouseName: warehouseName,
              weight: weight,
              weightUnit: this.unit,
              price: price,
              priceUnit: `元/${this.unit}`,
              amountOfMoney: this.amountOfMoney,
            });
            //获取合同模板
            this.getContractTemplate();
          } else {
            this.tableData.forEach((item) => {
              if (item.id == this.itemForm.id) {
                // item.categoryId = this.currentCategoryId
                // item.categoryName = categoryName
                item.commodityId = this.currentCommodityId;
                item.commodityName = productName;
                item.productorId = this.currentProductorId;
                item.productorName = brandName;
                item.warehouseId = this.currentWarehouseId;
                item.warehouseName = warehouseName;
                item.weight = weight;
                item.weightUnit = this.unit;
                item.price = price;
                item.priceUnit = `元/${this.unit}`;
                item.amountOfMoney = this.amountOfMoney;
              }
            });
            //获取合同模板
            this.getContractTemplate();
          }
          let sum = 0;
          let sumW = 0;
          this.tableData.forEach((item) => {
            if (item.id) {
              sum = BigNumber(sum).plus(item.amountOfMoney).toNumber();
              sumW = BigNumber(sumW).plus(item.weight).toNumber();
            }
          });
          this.totalAmount = sum;
          this.tableData.forEach((item) => {
            if (item.commodityName == "合计") {
              item.amountOfMoney = sum;
              item.weight = sumW;
            }
          });
        } else {
          this.hasError = true;
        }
      });
    },
    goonAdd(formName) {
      this.isEdit = false;
      this.submitItems(formName, "goonAdd");
      if (!this.hasError) {
        Object.keys(this.itemForm).forEach((item) => {
          this.itemForm[item] = "";
        });
        // (this.currentCategoryId = ""),
        //   (this.currentCommodityId = ""),
        //   (this.currentProductorId = ""),
        //   (this.currentWarehouseId = "");
        this.$refs.itemForm.resetFields();
      }
      if (this.tableData.length >= 2) {
        this.$set(
          this.itemForm,
          "productName",
          this.tableData[0].commodityName
        );
        this.currentCommodityId = this.selectItem.productId;
        this.itemForm.productName = this.selectItem.productName;
        setTimeout(() => {
          this.itemForm.productName = this.selectItem.productName;
        }, 0);
        this.unit = this.selectItem.sweightunit;
        this.itemForm.brandName = "";
      }
    },
    updateItem(data) {
      this.isEdit = true;
      this.dialogFormVisible = true;
      let tempData = JSON.parse(JSON.stringify(data));
      tempData.productName = tempData.commodityName;
      tempData.brandName = tempData.productorName;
      this.itemForm = tempData;

      this.currentCategoryId = tempData.commodityId;
      this.currentCommodityId = tempData.commodityId;
      this.currentProductorId = tempData.productorId;
      this.currentWarehouseId = tempData.warehouseId;
    },
    deleteItem(index, data) {
      data.splice(index, 1);
      let sum = 0;
      this.tableData.forEach((item) => {
        if (item.id) {
          sum += +item.amountOfMoney;
        }
      });
      this.tableData.forEach((item) => {
        if (item.commodityName == "合计") {
          item.amountOfMoney = sum;
        }
      });
      //获取合同模板
      this.getContractTemplate();
    },

    //form表单搜索
    showCategory() {
      this.showCategoryContainer = true;
      this.showCategorylist = false;
    },
    showCommodity() {
      this.showCommodityContainer = true;
      this.showCommoditylist = false;
    },
    showProductor() {
      this.showProductorContainer = true;
      this.showProductorlist = false;
    },
    showWarehouse() {
      this.showWarehouseContainer = true;
      this.showWarehouselist = false;
    },
    hoverCategory(data) {
      this.cateContent = data;
    },
    hoverCommodity(data) {
      this.comContent = data;
    },
    hoverProductor(data) {
      this.proContent = data;
    },
    hoverWarehouse(data) {
      this.wareContent = data;
    },
    showAllCategory() {
      this.cateContent = this.allCategory;
    },
    showAllCommodity() {
      this.comContent = this.allCommodity;
    },
    showAllProductor() {
      this.proContent = this.allProductor;
    },
    showAllWarehouse() {
      this.wareContent = this.allWarehouse;
    },
    searchCategory() {
      this.showCategorylist = true;
      this.showCategoryContainer = false;
      let queryString = this.itemForm.productName;
      this.categorySearchResult = queryString
        ? this.allCategory.filter(
            this.createStateFilter(queryString, "category")
          )
        : this.allCategory;
    },
    searchCommodity() {
      this.showCommoditylist = true;
      this.showCommodityContainer = false;
      let queryString = this.itemForm.productName;
      this.commoditySearchResult = queryString
        ? this.allCommodity.filter(
            this.createStateFilter(queryString, "productName")
          )
        : this.allCommodity;
    },
    searchProductor() {
      this.showProductorlist = true;
      this.showProductorContainer = false;
      let queryString = this.itemForm.brandName;
      this.productorSearchResult = queryString
        ? this.allProductor.filter(
            this.createStateFilter(queryString, "producer")
          )
        : this.allProductor;
    },
    searchWarehouse() {
      this.showWarehouselist = true;
      this.showWarehouseContainer = false;
      let queryString = this.itemForm.warehouseName;
      this.warehouseSearchResult = queryString
        ? this.allWarehouse.filter(
            this.createStateFilter(queryString, "warehouse")
          )
        : this.allWarehouse;
    },
    hideContainer(event) {
      let category = document.getElementById("category");
      let commodity = document.getElementById("commodity");
      let productor = document.getElementById("productor");
      let warehouse = document.getElementById("warehouse");

      let cateFooter = document.getElementById("cateFooter");
      let comFooter = document.getElementById("comFooter");
      let proFooter = document.getElementById("proFooter");
      let wareFooter = document.getElementById("wareFooter");

      if (category) {
        if (!category.contains(event.target)) {
          if (cateFooter && cateFooter.contains(event.target)) {
            return;
          } else {
            this.showCategoryContainer = false;
            if (this.showCategorylist) {
              this.showCategorylist = false;
              this.itemForm.productName = "";
            }
          }
        }
      }
      if (commodity) {
        if (!commodity.contains(event.target)) {
          if (comFooter && comFooter.contains(event.target)) {
            return;
          } else {
            this.showCommodityContainer = false;
            if (this.showCommoditylist) {
              this.showCommoditylist = false;
              this.itemForm.productName = "";
            }
          }
        }
      }
      if (productor) {
        if (!productor.contains(event.target)) {
          if (proFooter && proFooter.contains(event.target)) {
            return;
          } else {
            this.showProductorContainer = false;
            if (this.showProductorlist) {
              this.showProductorlist = false;
              // this.itemForm.brandName = '';
            }
          }
        }
      }
      if (warehouse) {
        if (!warehouse.contains(event.target)) {
          if (wareFooter && wareFooter.contains(event.target)) {
            return;
          } else {
            this.showWarehouseContainer = false;
            if (this.showWarehouselist) {
              this.showWarehouselist = false;
              this.itemForm.warehouseName = "";
            }
          }
        }
      }
    },

    //品类搜索
    querySearchSCategory(tag) {
      let params = {
        process_id: "00000213",
        nextProcessNode: "002",
        addr: this.url.categorySearchUrl.addr, //获取蕉农合同信息列表 接口返回的url.categorySearchUrl.addr 的值
        nodeInfo: {
          size: -1, //分页页容量（从0开始，-1代表查全部）
          page: 0, //分页页码（从0开始）
          returnValueType: tag,
        },
      };
      this.$post("a6c611cc-ded1-4a49-8823-0c66f6ebf7d9", params).then((res) => {
        if (res.succeed == 0) {
          const { data } = res;
          if (tag) {
            this.categoryContent = data.content;
          } else {
            this.allCategory = data.content;
            this.cateContent = this.allCategory;
          }
        }
      });
    },
    //品名搜索
    querySearchSCategoryName(tag) {
      let params = {
        process_id: "00000206",
        nextProcessNode: "003",
        execution_type: "get",
        // addr: this.url.commoditySearchUrl.addr, // 获取蕉农合同信息列表 接口返回的url.commoditySearchUrl.addr 的值
        // nodeInfo: {
        //   categoryId: this.currentCategoryId, // 品种id
        //   size: -1, // 分页页容量（从0开始，-1代表查全部）
        //   page: 0, // 分页页码（从0开始）
        //   returnValueType: tag
        // }
      };
      this.$post("a6c611cc-ded1-4a49-8823-0c66f6ebf7d9", params).then((res) => {
        if (res.succeed == 0) {
          const { data } = res;
          if (tag) {
            this.commodityContent = data;
          } else {
            this.allCommodity = data;
            this.comContent = this.allCommodity;
          }
        }
      });
    },
    //品牌搜索
    querySearchSProducer(tag) {
      let params = {
        process_id: "00000206",
        nextProcessNode: "005",
        execution_type: "get",
        // addr: this.url.productorSearchUrl.addr, // 获取蕉农合同信息列表 接口返回的url.commoditySearchUrl.addr 的值
        nodeInfo: {
          productId: this.currentCommodityId, // 品种id
          size: -1, // 分页页容量（从0开始，-1代表查全部）
          page: 0, // 分页页码（从0开始）
          returnValueType: tag,
        },
      };
      this.$post("a6c611cc-ded1-4a49-8823-0c66f6ebf7d9", params).then((res) => {
        if (res.succeed == 0) {
          const { data } = res;
          if (tag) {
            this.productorContent = data;
          } else {
            this.allProductor = data;
            this.proContent = this.allProductor;
          }
        }
      });
    },
    //交货仓库搜索
    querySearchSWarehouse(tag) {
      let params = {
        process_id: "00000206",
        nextProcessNode: "002",
        // execution_type: 'get',
        addr: this.url.warehouseSearchUrl.addr, // 获取蕉农合同信息列表 接口返回的url.warehouseSearchUrl.addr 的值
        nodeInfo: {
          size: -1, // 分页页容量（从0开始，-1代表查全部）
          page: 0, // 分页页码（从0开始）
          returnValueType: tag,
        },
      };
      this.$post("a6c611cc-ded1-4a49-8823-0c66f6ebf7d9", params).then((res) => {
        if (res.succeed == 0) {
          const { data } = res;
          if (tag) {
            this.warehouseContent = data.content;
          } else {
            this.allWarehouse = data.content;
            this.wareContent = this.allWarehouse;
          }
        }
      });
    },
    //卖方公司名搜索
    querySearchSellerCompany(queryString, cb) {
      let params = {
        process_id: "00000213",
        execution_type: "get", //get 代表读请求 获取数据,  set	代表写请求 编辑等操作
        nextProcessNode: this.searchBtnId, // 上面接口的  button.NodeId_1字段的值
        nodeInfo: {
          companyName: this.sellerCompanyName.split("【")[0], //公司名称
          size: -1, //分页页容量（从0开始，-1代表查全部）
          page: 0, //分页页码（从0开始）
        },
      };
      this.$post("a6c611cc-ded1-4a49-8823-0c66f6ebf7d9", params)
        .then((res) => {
          const { data } = res;
          this.options = data.content;
        })
        .catch((err) => {});
    },
    createStateFilter(queryString, tag) {
      if (tag == "category") {
        return (state) => {
          return (
            state.productName.toLowerCase().indexOf(queryString.toLowerCase()) >
            -1
          );
        };
      } else if (tag == "productName") {
        return (state) => {
          return (
            state.productName.toLowerCase().indexOf(queryString.toLowerCase()) >
            -1
          );
        };
      } else if (tag == "producer") {
        return (state) => {
          return (
            state.brandName.toLowerCase().indexOf(queryString.toLowerCase()) >
            -1
          );
        };
      } else if (tag == "warehouse") {
        return (state) => {
          return (
            state.warehouseName
              .toLowerCase()
              .indexOf(queryString.toLowerCase()) > -1
          );
        };
      }
    },
    handleSelectSCategory(item) {
      this.currentCategoryId = item.categoryId;
      this.itemForm.productName = item.productName;
      setTimeout(() => {
        this.itemForm.productName = item.productName;
      }, 0);
      this.itemForm.productName = "";
      this.itemForm.brandName = "";
      this.querySearchSCategoryName();
      this.querySearchSCategoryName("classifiedByPinyin");
    },
    handleSelectCategoryName(item) {
      this.currentCommodityId = item.productId;
      this.itemForm.productName = item.productName;
      setTimeout(() => {
        this.itemForm.productName = item.productName;
      }, 0);
      this.unit = item.sweightunit;
      this.itemForm.brandName = "";
      this.selectItem = item;
      this.querySearchSProducer();
      this.querySearchSProducer("classifiedByPinyin");
    },
    handleSelectProducer(item) {
      this.currentProductorId = item.brandId;
      this.itemForm.brandName = item.brandName;
      setTimeout(() => {
        this.itemForm.brandName = item.brandName;
      }, 0);
    },
    handleSelectWarehouse(item) {
      this.currentWarehouseId = item.warehouseId;
      this.itemForm.warehouseName = item.warehouseName;
      setTimeout(() => {
        this.itemForm.warehouseName = item.warehouseName;
      }, 0);
    },
    handleSelectBuyerCompany(item) {
      let users = item.users;
      this.unifiedSocialCreditCode =
        item.companyDetail?.unifiedSocialCreditCode;
      this.registerAddress = item.companyDetail?.registerAddress;
      this.legalPerson = item.companyDetail?.legalPerson;
      this.sellerCompanyName = `${item.companyName}【${
        users[users.length - 1].name
      }】`;
      this.sellerCompanyNameTemp = `${item.companyName}`;
      this.searchCompanies();
    },
    getContractTemplate() {
      this.isShowTip = false;
      let categoryId = [],
        productId = [];
      this.templateNameList = [];
      this.tableData.forEach((item) => {
        if (item.productorName) {
          categoryId.push(item.productorName);
          productId.push(item.commodityId);
        }
      });
      let params = {
        process_id: "00000206",
        nextProcessNode: "004",
        addr: "listTemplates", // this.url.contractTemplateSearchUrl.addr, //获取列表 接口返回的url.contractTemplateSearchUrl.addr 的值
        nodeInfo: {
          commodityId: productId, // 品种id
          // productId: productId, // 品名id
          // size: -1, // 分页页容量（从0开始，-1代表查全部）
          // page: 0 // 分页页码（从0开始）
        },
      };
      this.$post("a6c611cc-ded1-4a49-8823-0c66f6ebf7d9", params)
        .then((res) => {
          const { data } = res;
          if (data.content.length == 0) {
            this.isShowTip = true;
          }
          this.contractTemplateId = "";
          data.content.forEach((item) => {
            this.templateNameList.push({
              contractTemplateId: item.templateId,
              contractTemplateName: item.templateName,
            });
          });
        })
        .catch((err) => {});
    },
    checkNum() {
      this.contractNumber = this.contractNumber.replace(/[^\w]/g, "");
    },
    gotoContractTemp(templateId) {
      this.$router.push({
        path: "/contractTempDetail",
        query: { id: templateId, process_id: "00000213" },
      });
    },
    gotoContractTempOnline() {
      this.$router.push({
        path: "/contractTempOnlineDetail",
      });
    },
    cancle() {
      this.sellerCompanyName = "";
      this.contractNumber = "";
      this.signAddress = "";
      this.form.signType = "0";
      this.tableData = [
        {
          commodityName: "合计",
          amountOfMoney: 0.0,
          weight: 0.0,
        },
      ];
      this.templateNameList = [];
    },
    // 第二步 确认合同条款
    renderDom() {
      if (this.tag === "buyer") {
        let buyerContractInfo = JSON.parse(
          localStorage.getItem("buyerContractInfo")
        );

        // 获取机构信息
        this.getOrganizeInfo(buyerContractInfo.sellerCompanyId, "saler"); // 卖方
        this.getOrganizeInfo(this.userInfo.organizationId[0], "buyer"); // 买方

        this.goodsData = buyerContractInfo.goodsData;
        this.contractDate = buyerContractInfo.contractDate;
        this.contractNumber = buyerContractInfo.contractNumber;
        this.signAddress = buyerContractInfo.signAddress;
        this.totalAmount = buyerContractInfo.totalAmount;
        this.totalWeight = buyerContractInfo.totalWeight;
      } else {
        let salerContractInfo = JSON.parse(
          localStorage.getItem("salerContractInfo")
        );

        // 获取机构信息
        this.getOrganizeInfo(salerContractInfo.buyerCompanyId, "buyer"); // 买方
        this.getOrganizeInfo(this.userInfo.organizationId[0], "saler"); // 卖方

        this.goodsData = salerContractInfo.goodsData;
        this.contractDate = salerContractInfo.contractDate;
        this.contractNumber = salerContractInfo.contractNumber;
        this.signAddress = salerContractInfo.signAddress;
        this.totalAmount = salerContractInfo.totalAmount;
        this.totalWeight = salerContractInfo.totalWeight;
      }
    },
    getContractItems() {
      let params = {
        process_id: this.tag === "buyer" ? "00000207" : "00000206",
        nextProcessNode: "001",
        addr: "getContractTemplateByContractTemplateId",
        nodeInfo: {
          contractTemplateId: this.contractTemplateId,
        },
      };
      this.$post("a6c611cc-ded1-4a49-8823-0c66f6ebf7d9", params)
        .then((res) => {
          if (res.succeed === 0) {
            this.optionsHtml = res.data.contractTerms; // templateName
            this.templateName = res.data.templateName;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getOrganizeInfo(organizationId, tag) {
      let nparams = {
        process_id: this.tag === "buyer" ? "00000207" : "00000206",
        execution_type: "get", // get 代表读请求 获取数据,  set  代表写请求 编辑等操作
        nextProcessNode: "002", // 上面接口的  button.NodeId_2字段的值
        nodeInfo: {
          organizationId: organizationId, // this.buyerCompanyId        //机构id    搜索按钮返回的  "companyId": ""//公司id   这个字段的值
        },
      };
      this.$post("a6c611cc-ded1-4a49-8823-0c66f6ebf7d9", nparams)
        .then((res) => {
          if (tag === "buyer") {
            this.buyerInfo = res.data;
          } else if (tag === "saler") {
            this.salerInfo = res.data;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    updateItems() {
      this.contenteditable = true;
    },
    cancelUpdate() {
      this.contenteditable = false;
    },
    send() {
      if (!this.$refs.items?.textContent && this.form.signType !== "0") {
        this.$alert("条款不能为空");
        return;
      }
      if (this.form.signType !== "0") {
        this.contenteditable = false;
      }
      if (this.$refs.templateCon) {
        this.$refs.templateCon.showMark = true;
      }
      this.loading = this.$loading({
        lock: true,
        text: "加载中……",
        background: "rgba(0, 0, 0, 0.7)",
      });
      setTimeout(() => {
        if (this.tag === "buyer") {
          let buyerContractInfo = JSON.parse(
            localStorage.getItem("buyerContractInfo")
          );
          this.sendInfo(buyerContractInfo, "buyer");
        } else {
          let salerContractInfo = JSON.parse(
            localStorage.getItem("salerContractInfo")
          );
          this.sendInfo(salerContractInfo, "saler");
        }
      }, 100);
    },
    async sendInfo(data, tag) {
      if (this.form.signType !== "0") {
        await this.downPdf();
        setTimeout(() => {
          this.sendInfoCore(data, "", tag);
        }, 1000);
        return;
      }
      await this.downPdf();
      setTimeout(() => {
        // if(!this.pdfData){
        //   this.sendInfo(data, tag)
        //   return
        // }
        let p = new Promise((resolve, reject) => {
          // 上传 下载key获取
          let params = {
            process_id: "00000215",
            nextProcessNode: "004",
            addr: "getDownloadKey", // 获取列表 接口返回的url.downloadKeyUrl.addr 的值
            nodeInfo: {},
          };
          this.$post("a6c611cc-ded1-4a49-8823-0c66f6ebf7d9", params)
            .then((res) => {
              resolve(res.data);
            })
            .catch((err) => {
              console.log(err);
            });
        });
        p.then((res) => {
          const fd = new FormData();
          fd.append("file", this.pdfData);
          fd.append("key", res);
          fd.append("systemId", this.userInfo.id);
          fd.append(
            "orgId",
            this.userInfo.organizationId.length === 0
              ? ""
              : this.userInfo.organizationId[0]
          );
          this.loading = this.$loading({
            lock: true,
            text: "加载中……",
            background: "rgba(0, 0, 0, 0.7)",
          });
          http
            .post("/file/upload", fd)
            .then((res) => {
              let fileId = res.data?.data;
              this.sendInfoCore(data, fileId, tag);
            })
            .catch((err) => {
              this.loading.close();
              console.log(err);
            });
        });
      }, 1000);
    },
    sendInfoCore(data, fileId, tag) {
      let params = {
        process_id: tag === "saler" ? "00000206" : "00000213",
        execution_type: "set", // get 代表读请求 获取数据,  set代表写请求 编辑等操作
        nextProcessNode: "002", // 上面接口的  button.NodeId_2字段的值
        nodeInfo: {
          contractId: "", // 合同id（值为空表示新增，有值表示修改）
          contractType: tag === "saler" ? 0 : 2, // 合同类型（0-定向销售/1-线下销售/2-定向采购/3-线下采购）
          contractDate: data.contractDate, // 合同日期
          orderType: data.orderType, // 订单类型
          signType: data.signType, // 合同形式
          contractBase64: this.form.signType !== "0" ? this.pdfData : "", // pdfbase64
          fileId: fileId, // 文件id
          buyerCompanyId: data.buyerCompanyId, // 买家公司id（定向销售时传该参数）
          buyerCompanyName: data.buyerCompanyName, // 买家公司名称（定向销售时传该参数）
          buyerOperatorId: data.buyerOperatorId,
          buyerOperatorName: data.buyerOperatorName,
          sellerCompanyId: data.sellerCompanyId, // 卖家公司id（定向采购时传该参数）
          sellerCompanyName: data.sellerCompanyName, // 卖家公司名称（定向采购时传该参数）
          sellerOperatorId: data.sellerOperatorId,
          sellerOperatorName: data.sellerOperatorName,
          contractNumber: data.contractNumber, // 合同编号
          signAddress: data.signAddress, // 合同编号
          contractTemplateId: data.contractTemplateId, // 合同模板id（定向销售和定向采购时传该参数）
          contractTemplateName: data.contractTemplateName, // 合同模板名称（定向销售和定向采购时传该参数）
          contractAttachment: "", // 合同附件（线下销售和线下采购时传该参数）
          actualAmount: "", // 实际收取金额（线下销售和线下采购时传该参数）
          waterBillOrPaymentVoucher: "", // 水单或付款凭证（线下销售和线下采购时传该参数）
          ladingBill: "", // 提单（线下销售和线下采购时传该参数）
          contractDetails: data.goodsData,
        },
      };
      this.$post("a6c611cc-ded1-4a49-8823-0c66f6ebf7d9", params)
        .then((res) => {
          if (res.succeed === 0) {
            this.loading.close();
            if (tag === "saler") {
              this.$message.success("合同已创建成功，待双方签约确认。");
              this.$router.push("/contractManagement/contractManagement");
            } else if (tag === "buyer") {
              this.$message.success("合同已创建成功，待双方签约确认。");
              this.$router.push("/bContractManagement/bContractManagement");
            }
          }
        })
        .catch((err) => {
          this.loading.close();
          console.log(err);
        });
    },
    async downPdf() {
      let _this = this;
      document.documentElement.scrollTop = 0;
      let canvas = document.createElement("canvas"); // 生成canvas上下文
      let context = canvas.getContext("2d");
      let _articleHtml = "";
      let _articleHtml1 = "";
      let _articleHtml2 = "";
      let _articleHtml3 = "";
      let _articleHtml4 = "";
      let _articleHtml5 = "";
      let _articleHtml6 = "";
      if (this.form.signType === "0") {
        _articleHtml = document.getElementById("print-area");
        _articleHtml1 = document.getElementById("first-print");
        _articleHtml2 = document.getElementById("second-print");
        _articleHtml3 = document.getElementById("third-print");
        _articleHtml4 = document.getElementById("fourth-print");
        _articleHtml5 = document.getElementById("fifth-print");
        _articleHtml6 = document.getElementById("six-print");
        _articleHtml1.style.padding = "32px";
        _articleHtml2.style.padding = "32px";
        _articleHtml3.style.padding = "32px";
        _articleHtml4.style.padding = "32px";
        _articleHtml5.style.padding = "32px";
        _articleHtml6.style.padding = "32px";
      } else {
        _articleHtml = document.getElementById("print-area-Info");
      }

      // 以上部分都是为了强化清晰度的，放大canvas画布
      if (this.form.signType === "0") {
        canvas.width = contentWidth;
        canvas.height = contentHeight;
        context.scale(1, 1);
        let opts = {
          scale: 1,
          width: contentWidth, //dom 原始宽度
          height: contentHeight,
          canvas: canvas,
          useCORS: true, //允许canvas画布内可以跨域请求外部链接图片, 允许跨域请求。
        };
        let canvasArr = [];
        let p1 = html2canvas(_articleHtml1, this.createCav()).then(
          (canvasItem) => {
            canvasArr[0] = canvasItem;
          }
        );
        let p2 = html2canvas(_articleHtml2, this.createCav()).then(
          (canvasItem) => {
            canvasArr[1] = canvasItem;
          }
        );
        let p3 = html2canvas(_articleHtml3, this.createCav()).then(
          (canvasItem) => {
            canvasArr[2] = canvasItem;
          }
        );
        let p4 = html2canvas(_articleHtml4, this.createCav()).then(
          (canvasItem) => {
            canvasArr[3] = canvasItem;
          }
        );
        let p5 = html2canvas(_articleHtml5, this.createCav()).then(
          (canvasItem) => {
            canvasArr[4] = canvasItem;
          }
        );
        let p6 = html2canvas(_articleHtml6, this.createCav()).then(
          (canvasItem) => {
            canvasArr[5] = canvasItem;
          }
        );

        return Promise.all([p1, p2, p3, p4, p5, p6]).then((result) => {
          _this.createPdfAll(canvasArr[0], canvasArr);
        });
      } else {
        let _w = _articleHtml.clientWidth; //获取需要导出pdf区域的宽度和高度
        let _h = _articleHtml.clientHeight + 100;
        let scale = 3;
        if (_w > _h) {
          _h = _w;
        }
        canvas.width = _w * scale;
        canvas.height = _h * scale;
        context.scale(scale, scale);
        let opts = {
          scale: 1,
          width: _w, //dom 原始宽度
          height: _h,
          canvas: canvas,
          useCORS: true, //允许canvas画布内可以跨域请求外部链接图片, 允许跨域请求。
        };
        html2canvas(_articleHtml, opts).then((canvas) => {
          _this.createPdfAll(canvas);
        });
      }
    },
    createCav() {
      let canvas = document.createElement("canvas"); // 生成canvas上下文
      let context = canvas.getContext("2d");
      let dpr = 2;
      canvas.width = contentWidth * dpr;
      canvas.height = contentHeight * dpr;
      context.scale(1, 1);
      let opts = {
        scale: dpr,
        width: contentWidth * dpr, //dom 原始宽度
        height: contentHeight * dpr,
        canvas: canvas,
        useCORS: true, //允许canvas画布内可以跨域请求外部链接图片, 允许跨域请求。
      };
      return opts;
    },
    // 自定义宽高
    async createPdfAll(canvas, canvasArr) {
      if (this.form.signType !== "0") {
        contentWidth = canvas.width / 3;
        contentHeight = canvas.height / 3;
      }
      let pdf = new jsPDF("", "pt", [contentWidth, contentHeight]); //自定义宽高
      let pageData = "";
      if (canvasArr?.length > 0) {
        canvasArr.forEach((item, index) => {
          pdf.addImage(
            item.toDataURL("image/jpeg", 1.0),
            "JPEG",
            0,
            0,
            contentWidth,
            contentHeight
          );
          if (index < canvasArr.length - 1) {
            pdf.addPage();
          }
        });
      } else {
        pageData = canvas.toDataURL("image/jpeg", 1.0); //转换图片为dataURL
        pdf.addImage(pageData, "JPEG", 0, 0, contentWidth, contentHeight); //添加图像到页面
      }

      // pdf.save('合同.pdf')
      if (this.form.signType === "0") {
        this.pdfData = await this.dataURLtoFile(
          pdf.output("datauristring"),
          "购销合同.pdf"
        );
      } else {
        this.pdfData = await pdf.output("datauristring");
      }

      // let url = window.URL.createObjectURL(new Blob([this.pdfData]))
      // let link = document.createElement('a')
      // link.style.display = 'none'
      // link.href = url
      // link.setAttribute('download', 'xz.pdf')
      // link.click()

      if (this.form.signType === "0") {
        setTimeout(() => {
          if (this.form.signType === "0") {
            document.getElementById("first-print").style.padding = "0";
            document.getElementById("second-print").style.padding = "0";
            document.getElementById("third-print").style.padding = "0";
            document.getElementById("fourth-print").style.padding = "0";
            document.getElementById("fifth-print").style.padding = "0";
            document.getElementById("six-print").style.padding = "0";
          }
        }, 100);
      }
    },
    async dataURLtoFile(dataurl, filename) {
      var arr = dataurl.split(","),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new Promise((resolve) => {
        resolve(new File([u8arr], filename, { type: mime }));
      });
    },
    toBlob(base64Data) {
      let byteString = base64Data;
      if (base64Data.split(",")[0].indexOf("base64") >= 0) {
        byteString = atob(base64Data.split(",")[1]); // base64 解码
      } else {
        byteString = unescape(base64Data.split(",")[1]);
      }
      // 获取文件类型
      const mimeString = base64Data.split(";")[0].split(":")[1]; // mime类型

      // ArrayBuffer 对象用来表示通用的、固定长度的原始二进制数据缓冲区
      // let arrayBuffer = new ArrayBuffer(byteString.length) // 创建缓冲数组
      // let uintArr = new Uint8Array(arrayBuffer) // 创建视图

      const uintArr = new Uint8Array(byteString.length); // 创建视图

      for (let i = 0; i < byteString.length; i += 1) {
        uintArr[i] = byteString.charCodeAt(i);
      }
      // 生成blob
      const blob = new Blob([uintArr], {
        type: mimeString,
      });
      // 使用 Blob 创建一个指向类型化数组的URL, URL.createObjectURL是new Blob文件的方法,可以生成一个普通的url,可以直接使用,比如用在img.src上
      return blob;
    },
    forceText() {
      if (this.form.signType === "0") {
        this.$refs.templateCon.showMark = false;
        this.$refs.templateCon.getFocus();
      } else {
        this.contenteditable = true;
        this.showSave = true;
      }
    },
    saveText() {
      this.contenteditable = false;
      this.showSave = false;
    },
    print() {
      if (this.form.signType === "0") {
        this.$refs.templateCon.showMark = true;
        this.$refs.templateCon.print();
      } else {
        setTimeout(() => {
          let style =
            "@page { .repatBr{ display: none !important; } }  @media print { .repatBr{ display: block !important; }   .mark-area { display: none !important;} .info-title{text-align: center;font-weight: bold;color: #000;font-size: 34px;padding: 20px;}  }";
          printJS({
            printable: "print-area-Info",
            type: "html",
            targetStyles: ["*"],
            style: style,
          });
        }, 100);
      }
    },
    goFirst() {
      this.active = "1";
    },

    // 第三步

    getDetail() {
      let row = store.state.Ylt.contractRow;
      this.contractStatusId = row.contractStatus;
      this.contractId = row.contractId;
      this.buyerId = row.buyerId;
      this.sellerId = row.sellerId;
      this.constractDetail = true;
      this.signType = row.signType;
      this.pageTag = "";

      let params = {
        process_id: "00000215",
        execution_type: "get", // get 代表读请求 获取数据,  set 代表写请求 编辑等操作
        nextProcessNode: "001", // 上面接口的  operate.node_id字段的值
        nodeInfo: {
          contractId: this.contractId, // 合同id
        },
      };
      this.$post("a6c611cc-ded1-4a49-8823-0c66f6ebf7d9", params)
        .then((res) => {
          const { data } = res;
          this.contract = data.contract;
          this.fileId = this.contract.transferOrderFileId;
          this.fileIdNew = this.contract.fileId;
          this.transferWeight = data.contract.transferWeight || "";
          this.transferOrderStatusDesc = this.contract.transferOrderStatusDesc;
          this.detailTableData = data.contract.details;

          this.contractStatusId = this.contract.contractStatus;

          this.contractTableData.forEach((item) => {
            if (item.tag === "buyer") {
              item.file = this.contract.buyerContractFile;
              item.showUpload =
                this.buyerId === this.userInfo.organizationId[0];
            } else if (item.tag === "seller") {
              item.file = this.contract.sellerContractFile;
              item.showUpload =
                this.sellerId === this.userInfo.organizationId[0];
            } else if (item.tag === "both") {
              item.file = this.contract.twoChapterContractFile;
            }
          });

          this.buyerContractFile = this.contract.buyerContractFile;
          this.sellerContractFile = this.contract.sellerContractFile;
          this.twoChapterContractFile = this.contract.twoChapterContractFile;

          this.fileUrlList = [];
          this.billUrlList = [];
          this.credentialsUrlList = [];

          if (data.contract.offlineContractAttachment.length > 1) {
            let offlineContractAttachment = JSON.parse(
              data.contract.offlineContractAttachment
            );
            offlineContractAttachment.forEach((item) => {
              // 转图片地址
              this.getImg(item, "file");
            });
          }

          if (data.contract.offlineWaterBillOrPaymentVoucher.length > 1) {
            let offlineLadingBill = JSON.parse(data.contract.offlineLadingBill);
            offlineLadingBill.forEach((item) => {
              // 转图片地址
              this.getImg(item, "bill");
            });
          }

          if (data.contract.offlineWaterBillOrPaymentVoucher.length > 1) {
            let offlineWaterBillOrPaymentVoucher = JSON.parse(
              data.contract.offlineWaterBillOrPaymentVoucher
            );
            offlineWaterBillOrPaymentVoucher.forEach((item) => {
              // 转图片地址
              this.getImg(item, "credentials");
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    addRemark() {
      this.dialogFormVisible = true;
    },
    getWeightTonTotel(transferOrderStatusDesc) {
      if (transferOrderStatusDesc === "已过户") {
        let weight = this.transferWeight / 1000;
        return "已过户" + weight.toFixed(4) + "吨";
      } else {
        return transferOrderStatusDesc;
      }
    },
    submitRemark() {
      this.dialogFormVisible = false;
      this.contract.remark = this.remarkForm.remark;
      this.isEdit = true;
      // 添加备注
      let params = {
        process_id: "00000215",
        execution_type: "set", // get 代表读请求 获取数据,  set  代表写请求 编辑等操作
        nextProcessNode: "007", // 上面接口的  button.NodeId_5字段的值
        nodeInfo: {
          contractId: this.contractId, // 合同id
          remark: this.remarkForm.remark, // 合同备注
        },
      };
      this.$post("a6c611cc-ded1-4a49-8823-0c66f6ebf7d9", params)
        .then((res) => {
          if (res.succeed === 0) {
            this.$message({
              message: `${res.errorMsg}`,
              type: "success",
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    confirm() {
      if (this.contractStatusId === "90") {
        if (this.userInfo.organizationId[0] === this.buyerId) {
          this.updateContractStatus("cancel");
        } else if (this.userInfo.organizationId[0] === this.sellerId) {
          this.updateContractStatus("confirm");
        } else {
          this.updateContractStatus("cancel");
        }
      } else if (this.contractStatusId === "91") {
        if (this.userInfo.organizationId[0] === this.buyerId) {
          this.updateContractStatus("confirm");
        } else if (this.userInfo.organizationId[0] === this.sellerId) {
          this.updateContractStatus("cancel");
        } else {
          this.updateContractStatus("cancel");
        }
      } else if (
        this.contractStatusId == "70" ||
        this.contractStatusId == "96"
      ) {
        this.updateContractStatus("cancel");
      }
    },
    updateContractStatus(tag) {
      let contractStatus = "";
      if (tag === "cancel") {
        contractStatus =
          this.userInfo.organizationId[0] === this.buyerId
            ? "83"
            : this.userInfo.organizationId[0] === this.sellerId
            ? "82"
            : "83";
      } else if (tag === "confirm") {
        contractStatus =
          this.contract.contractType === "1" ||
          this.contract.contractType === "3"
            ? "400"
            : "99";
      } else if (tag === "upload") {
        contractStatus = "100";
      } else if (tag === "offlinePay") {
        contractStatus = "201";
      } else if (tag === "confirmGoods") {
        contractStatus = "400";
      }

      let params = {
        process_id: "00000215",
        execution_type: "set", // get 代表读请求 获取数据,  set 代表写请求 编辑等操作
        nextProcessNode: "001", // 上面接口的  operate.node_id字段的值
        nodeInfo: {
          contractId: this.contractId, // 合同id
          contractStatus: contractStatus, // 合同状态
        },
      };
      this.$post("a6c611cc-ded1-4a49-8823-0c66f6ebf7d9", params)
        .then((res) => {
          if (res.succeed === 0) {
            this.goback();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    downloadFile(rowData, type) {
      this.downType = type;
      if (rowData.length <= 0) {
        this.$message.error("未找到文件!");
        return;
      }
      if (rowData.length === 1) {
        http
          .get("/file/getFilePath?fileId=" + rowData[0])
          .then((res) => {
            const { data } = res.data;
            let url = data["common_file@path"];
            window.open(`/downfile${url}`, "_blank");
          })
          .catch((err) => {});
      } else {
        this.downRowData = [];
        rowData.forEach((item) => {
          this.downRowData.push({ id: item });
        });
        this.showMoreDown = true;
      }
    },
    lookFileNew(fileId) {
      if (this.signType !== "0") {
        this.contractTableData.forEach((item) => {
          item.showUpload = false;
          if (item.tag === "buyer") {
            item.file = this.contract.buyerContractFile;
          } else if (item.tag === "seller") {
            item.file = this.contract.sellerContractFile;
          } else if (item.tag === "both") {
            item.file = this.contract.twoChapterContractFile;
          }
        });
        this.contractVisible = true;
        return;
      }
      http
        .get("/file/getFilePath?fileId=" + fileId)
        .then((res) => {
          const { data } = res.data;
          let url = data["common_file@path"];
          window.open(`/downfile${url}`, "_blank");
        })
        .catch((err) => {});
    },
    downloadFileNew(fileId) {
      http
        .get("/file/path/download?fileId=" + fileId)
        .then((res) => {
          const { data } = res.data;
          let url = data["common_file@path"];
          window.open(`/downfile${url}`, "_blank");
        })
        .catch((err) => {});
    },
    cancel() {
      this.updateContractStatus("cancel");
    },
    offlinePay() {
      this.updateContractStatus("offlinePay");
    },
    confirmGoods() {
      this.updateContractStatus("confirmGoods");
    },
    showContractUpload(tag) {
      this.contractVisible = true;
      this.$nextTick(() => {
        this.$refs.uploadboth.clearFiles();
        this.$refs.uploadbuyer.clearFiles();
        this.$refs.uploadseller.clearFiles();
      });

      this.uploadStatus = tag;
    },
    confirmContract() {
      if (
        this.buyerContractFile == "" &&
        this.sellerContractFile == "" &&
        this.twoChapterContractFile == ""
      ) {
        this.$alert("请上传合同文件");
        return;
      }
      let params = {
        process_id: "00000208",
        execution_type: "set",
        nextProcessNode: "002",
        nodeInfo: {
          contractId: this.contractId,
          buyerContractFile: this.buyerContractFile,
          sellerContractFile: this.sellerContractFile,
          twoChapterContractFile: this.twoChapterContractFile,
        },
      };
      this.$post("a6c611cc-ded1-4a49-8823-0c66f6ebf7d9", params)
        .then((res) => {
          if (res.succeed == 0) {
            this.$message({
              message: res.errorMsg,
              type: "success",
            });
            this.contractVisible = false;

            this.contractTableData.forEach((item) => {
              if (item.tag == "buyer") {
                item.file = this.buyerContractFile;
                item.showUpload =
                  this.buyerId == this.userInfo.organizationId[0];
              } else if (item.tag == "seller") {
                item.file = this.sellerContractFile;
                item.showUpload =
                  this.sellerId == this.userInfo.organizationId[0];
              } else if (item.tag == "both") {
                item.file = this.twoChapterContractFile;
              }
            });
            // if (this.uploadStatus == 'upload' && this.twoChapterContractFile) {
            //   this.updateContractStatus(this.uploadStatus);
            // }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    check(tag) {
      if (tag == "buyer") {
        this.getImg(this.buyerContractFile, tag);
      } else if (tag == "seller") {
        this.getImg(this.sellerContractFile, tag);
      } else if (tag == "both") {
        this.getImg(this.twoChapterContractFile, tag);
      }
    },
    getImg(rowData, tag) {
      http.get("/file/getFilePath?fileId=" + rowData).then((res) => {
        const { data } = res.data;
        if (tag === "file") {
          this.fileUrlList.push({
            name: data["common_file@file_name"],
            url: data["common_file@path"],
          });
        } else if (tag === "bill") {
          this.billUrlList.push({
            name: data["common_file@file_name"],
            url: data["common_file@path"],
          });
        } else if (tag === "credentials") {
          this.credentialsUrlList.push({
            name: data["common_file@file_name"],
            url: data["common_file@path"],
          });
        } else if (tag === "buyer" || tag === "seller" || tag === "both") {
          this.showImg(data["common_file@path"]);
        }
      });
    },
    showImg(url, name) {
      window.open(`/downfile${url}`, "_blank");
    },
    uploadContract(param) {
      let p = new Promise((resolve, reject) => {
        // 上传 下载key获取
        let params = {
          process_id: "00000208",
          nextProcessNode: "004",
          addr: "getDownloadKey", // 获取列表 接口返回的url.downloadKeyUrl.addr 的值
          nodeInfo: {},
        };
        this.$post("a6c611cc-ded1-4a49-8823-0c66f6ebf7d9", params)
          .then((res) => {
            resolve(res.data);
          })
          .catch((err) => {
            console.log(err);
          });
      });

      p.then((res) => {
        const fd = new FormData();
        fd.append("file", param.file);
        fd.append("key", res);
        fd.append("systemId", this.userInfo.id);
        fd.append("orgId", this.userInfo.organizationId[0]);

        http.post("/file/upload", fd).then((res) => {
          const { data } = res.data;
          if (param.data.tag == "buyer") {
            this.buyerContractFile = data;
          } else if (param.data.tag == "seller") {
            this.sellerContractFile = data;
          } else if (param.data.tag == "both") {
            this.twoChapterContractFile = data;
          }
        });
      });
    },
    showFile(tag) {
      this.fileVisible = true;
      if (tag == "credentials") {
        this.urlList = this.credentialsUrlList;
      } else if (tag == "bill") {
        this.urlList = this.billUrlList;
      } else if (tag == "file") {
        this.urlList = this.fileUrlList;
      }
    },
    goback() {
      this.$router.back();
    },
  },
};
</script>

<style lang="scss" scoped>
.repatBr {
  display: none !important;
}
@media print {
  .step-area {
    display: none !important;
  }
  .btn-group {
    display: none !important;
  }

  .head-title {
    display: none !important;
  }

  .mark-area {
    display: none !important;
  }
}
.title {
  border-bottom: 6px solid #1d74e0;
  height: 35px;
  line-height: 27px;
  margin-top: 15px;
  font-size: 16px;
  font-weight: bold;
  color: #666666;
  padding-left: 30px;
}
.form {
  margin-top: 10px;
  // padding: 24px 0;
  font-size: 14px;
  .hr {
    border-bottom: 1px solid #e6e6e6;
    height: 0;
    margin-bottom: 10px;
    margin-top: 13px;
  }
}
.block,
.block-1 {
  display: flex;
  align-items: center;
  .label {
    width: 100px;
    height: 45px;
    line-height: 45px;
    color: #333;
    text-align: right;
    margin-right: 18px;
  }
  .content {
    color: #888;
  }
  .content-block {
    display: flex;
    align-items: center;
    .radio-item {
      vertical-align: bottom;
      margin-left: 2px;
      cursor: pointer;
      &:hover {
        color: orange;
      }
    }
    .content-block__label {
      width: 85px;
      text-align: right;
    }
    .content-block__content {
      display: flex;
      align-items: center;
      .btn {
        background: #2d5171;
        padding: 5px 32px;
        color: #ffffff;
        font-size: 12px;
        cursor: pointer;
        margin-left: 10px;
      }
    }
  }
  .content-block-template {
    display: flex;
    flex-wrap: wrap;
    // margin-top: 10px;
    label {
      height: 26px;
      line-height: 26px;
    }
    .radio-item {
      vertical-align: bottom;
      margin-left: 2px;
      cursor: pointer;
      &:hover {
        color: orange;
      }
    }
  }
  .add-item-btn {
    background: #8b0629;
    padding: 6px 32px;
    color: #ffffff;
    font-size: 12px;
    cursor: pointer;
    margin-bottom: 5px;
  }
}
.block-1 {
  display: block;
  margin-left: 100px;
  position: relative;
}
.send-btn {
  background: #8b0629;
  margin-left: 20px;
  width: 102px;
  height: 30px;
  line-height: 30px;
  color: #ffffff;
  cursor: pointer;
  text-align: center;
  font-size: 12px;
  margin-top: 10px;
  margin-bottom: 30px;
}
.add-dialog-footer {
  display: flex;
  text-align: center;
  margin-left: 100px;
  .btn {
    width: 100px;
    height: 30px;
    line-height: 30px;
    background: #890b2b;
    border-radius: 0.25em;
    font-weight: bold;
    color: #ffffff;
    margin-right: 10px;
    cursor: pointer;
    &.cancel,
    &.confirm {
      background: #2d5171;
    }
  }
}
.container-alert {
  position: absolute;
  z-index: 99;
  width: 480px;
  border: 1px solid rgb(47, 116, 149);
  background: #fff;
  .head {
    background: #67a1e2;
    color: #fff;
    font-size: 9pt;
    height: 24px;
    line-height: 24px;
    text-align: center;
  }
  .content {
    height: 180px;
    overflow: auto;
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
  }
  .content-item {
    width: 100px;
    margin-left: 10px;
    height: 30px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    cursor: pointer;
  }
  .footer {
    display: flex;
    height: 24px;
    background: rgb(200, 227, 252);
    .common {
      width: 40px;
      &:hover {
        background: rgb(232, 244, 255);
        cursor: pointer;
      }
    }
    .key-group {
      display: flex;
      justify-content: space-between;
      width: 100%;
      .key {
        width: 100%;
        text-align: center;
        &:hover {
          background: rgb(232, 244, 255);
          cursor: pointer;
          text-align: center;
        }
      }
    }
    div {
      line-height: 24px;
    }
  }
}
.list {
  position: absolute;
  z-index: 9999;
  width: 180px;
  border: 1px solid #2f7495;
  background: #fff;
  height: 145px;
  padding: 10px 0;
  box-sizing: border-box;
  overflow: auto;
  li {
    color: #21407d;
    padding-left: 10px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    &:hover {
      background: #c8e3fc;
    }
  }
}

// 步骤条
.step {
  display: flex;
  background: #07335d;
  height: 200px;
  align-items: center;
  padding: 0 165px;
  color: #fff;
}
.step-content {
  flex-basis: 33%;
  position: relative;
  width: 100%;
}
.step-line {
  border: 1px solid #fff;
  position: absolute;
  top: 26px;
  left: 6px;
  display: block;
  width: 100%;
}
.activeColor .step-line {
  border: 1px solid #5cffff;
}
.step-item {
  z-index: 1;
  position: relative;
  // background: #fff;
  // color: #FFF;
  width: 64px;
}
.step-icon img {
  z-index: 1;
  top: 12px;
}
.step-word {
  display: block;
  width: 100px;
  text-align: center;
  margin-left: -25px;
  margin-top: 10px;
}
.step-num {
  color: #fff;
  display: block;
  white-space: nowrap;
  background: #a7a7a7;
  width: 26px;
  height: 26px;
  text-align: center;
  border-radius: 50%;
  font-size: 20px;
  line-height: 26px;
  margin: 10px 37px;
}

// 步骤内容
.head-title {
  background: #e4e9f4;
  height: 35px;
  line-height: 35px;
  margin-top: 15px;
  font-size: 16px;
  font-weight: bold;
  color: #1a1a1a;
  padding-left: 30px;
}
.form-content {
  padding: 20px 30px;
  ::v-deep .el-input__suffix {
    left: 350px;
  }
}
.activeColor {
  color: #5cffff !important;
}
.activeColor .step-num {
  background: #5cffff !important;
}
// 第二步

.container {
  width: 700px;
  // margin: 0 auto;
  font-family: "arial,sans-serif", "宋体", "微软雅黑", "黑体";
  color: #444;
  letter-spacing: 0.01px;
  .container-title {
    font-size: 28px;
    line-height: 58px;
    text-align: center;
    color: #333;
    font-weight: bold;
  }
  .content {
    border: 1px solid #333;
    border-right: 0;
    .row-fst {
      display: flex;
      .left,
      .right {
        flex: 1;
        border-right: 1px solid #333;
        div {
          line-height: 30px;
        }
      }
    }
    .row-sec {
      display: table;
      width: 100%;
      text-align: center;
      .row {
        display: table-row;
        .cell {
          display: table-cell;
          border-top: 1px solid #333;
          border-right: 1px solid #333;
          line-height: 30px;
        }
      }
    }
    .row-last {
      border-top: 1px solid #333;
      border-right: 1px solid #333;
      line-height: 30px;
    }
  }
}

// 第三步
.c-title {
  color: #000000;
  font-size: 15px;
  height: 25px;
  // margin: 20px auto 10px;
  line-height: 25px;
  font-weight: bold;
}
.contract-detail {
  .no-title {
    position: absolute;
    top: -8px;
    left: 30px;
    background: #03325e;
    padding: 2px 20px 1px 6px;
    height: 23px;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: #ffffff;
  }

  .detail-form {
    // padding: 34px 45px;
    div {
      line-height: 30px;
      font-size: 12px;
      color: #000;
    }
    .label {
      display: inline-block;
      width: 60px;
      text-align: right;
      color: #7b7b7b;
      line-height: 25px;
      margin-right: 24px;
    }
  }

  .btn-group {
    display: flex;
    justify-content: center;
    color: #fff;
    width: 500px;
    margin: 20px auto;
    .btn {
      background: #2d5171;
      // width: 120px;
      height: 30px;
      line-height: 30px;
      font-size: 12px;
      color: #ffffff;
      padding: 0 18px;
      margin-right: 3px;
      cursor: pointer;
      margin-left: 5px;
      white-space: nowrap;
      &.sec {
        background: #890b2b;
      }
    }
  }
}

.manage {
  ::v-deep .el-card__body {
    padding: 0px;
  }
  ::v-deep .el-card {
    margin-bottom: 10px;
  }
}
.update-item {
  color: red;
}
.info-title {
  text-align: center;
  font-weight: bold;
  color: #000;
  font-size: 34px;
  padding: 20px;
}
</style>
